/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  @font-face {
    font-family: Avenir;
    src: url(../font/Metropolis-Light.otf) format("otf");
  }
}

h3{
  @apply text-2xl text-center font-semibold leading-normal;
}

h4{
  @apply text-xl font-bold pb-4;
}

.grid-2{
  @apply grid grid-cols-1 md:grid-cols-2 px-5 md:px-0 lg:space-x-6;
}

h1,h2,h3,h4, p, a{
  font-family: 'Avenir';
}

.triangle {
  height: auto;
  background: linear-gradient(to bottom right, #fff 0%, #fff 50%, #FFCC13 50%, #FFCC13 100%);
}

.triangle-right {
  height: auto;
  background: linear-gradient(to top right, #fff 0%, #fff 50%, #FFF5D1 50%, #FFF5D1 100%);
}

.triangle-left {
  height: auto;
  background: linear-gradient(to top left, #fff 0%, #fff 50%, #FFCC13 50%, #FFCC13 100%);
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
